import React, { useContext, useEffect, useMemo, useState } from 'react';
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreatChannelPartner from 'components/modals/CreatChannelPartner';
import EditChannelPartner from 'components/modals/EditChannelPartner';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import FallBack from "components/fallBack"
import { VIEWCREATECHANNELPARTNERAPI, DELETECREATECHANNELPARTNERAPI,VIEWCREATECHANNELPARTNERAPIChannel } from 'utils/Services';
import { toast } from 'react-toastify';


function ChannelPartner() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])





    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Channel Partner Name",
                accessor: (Customer) => Customer?.channel_partner_name || "hgiii" // Use conditional rendering
            },
            {
                Header: "Address", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.address1 || "" // Use conditional rendering
            },
            {
                Header: "City", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.city || "" // Use conditional rendering
            },
            {
                Header: "State", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.state || "" // Use conditional rendering
            },
            {
                Header: "Postal/Zip Code", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.zip_code || "" // Use conditional rendering
            },
        
            {
                Header: "GST No.", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.gst_no || "" // Use conditional rendering
            },
            {
                Header: "Actions", // The header text for this column is "Service"
                accessor: (row, index) => {
                    return (
                        <div className='d-flex  justify-content-between  align-items-center'>
                            <div className='col-lg-8' onClick={() => handle("edit", row?.id)}>
                                <EditDelete text="Edit" type="Button" />
                            </div>
                            {/* <div className='col-lg-6' onClick={() => handle("delete", row?.id)}>
                                <EditDelete text="Delete" type="Button" />
                            </div> */}

                        </div>



                    );
                }
            },
        ],
        []
    );
    

    const resultrole = localStorage.getItem('role');
    const channelid = localStorage.getItem('channelId');
    
    const fecthData = async () => {
      try {
        setLoading(true);
        let response;
    
        if (resultrole === "3" || resultrole === "2" && channelid) {
        //   response = await VIEWCREATECHANNELPARTNERAPIChannel(`?id=${channelid}`); 
        // } else {
          response = await VIEWCREATECHANNELPARTNERAPI(); 
        }
        if (response?.res?.status === 200) {
          setallData(response.res.data);
          
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    
    useEffect(() => {
        fecthData(); 
    }, []);
    


      

    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const result = await DELETECREATECHANNELPARTNERAPI(data);

            if (result?.res?.status == 200) {
                setLoading(false)
                toast.success(result?.res?.message)
                fecthData(); closeModal();
            }
        }
        catch (err) {
            toast.error(err?.message || err)
            setLoading(false)
            closeModal();
        }


    }
    const handle = (data, info) => {
        const CreateDocumnet = <CreatChannelPartner closeModal={closeModal} reload={fecthData} />;
        const EditDocumnet = <EditChannelPartner closeModal={closeModal} data={info} reload={fecthData} />;
        const DelteDocumnet = <CoomonDelete closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        switch (data) {
            case "create":
                handleModalData(CreateDocumnet, "lg");
                break;
            case "edit":
                handleModalData(EditDocumnet, "md");
                break;
            case "delete":
                handleModalData(DelteDocumnet, "md");
                break;

        }

    }
    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />

                <div className='d-flex justify-content-between  my-3 '>
                    <div className='col-lg-6'>

                    </div>
                    <div onClick={() => handle("create")}>
                        <Button text="Create New Channel Partner" />

                    </div>


                </div>

                {
                    allData.length > 0 ? (<div className='default_table'>
                        <CustomTable data={allData} columns={FeedsColumns} />


                    </div>) : (<FallBack />)
                }

            </div>
        </div>
    )
}

export default ChannelPartner