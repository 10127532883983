import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import ADD from "assets/img/dashboards/add_circle.svg";
import Delete from "assets/img/dashboards/delete.svg"
import { PLANDELETEEDITAPI, PLANUPDATEAPI, BRANCLOCATIONLISTAPI, VIEWCREATECHANNELPARTNERAPI } from 'utils/Services';
import * as yup from 'yup';
import { toast } from 'react-toastify';


function Subscription({ closeModal, reload, data }) {

    const [options, setOptions] = useState({
        partners: [], branch: [], status: [{
            id: true,
            label: 'Active',
            value: true
        }, {
            id: false,
            label: 'InActive',
            value: false
        }]
    });

      const [selectBranch, setSelectBranch] = useState();
      const [loading, setLoading] = useState(false)
      const [selectChannelPartner, setSelectChannelPartner] = useState()
      const [allData, setallData] = useState({})

     const fetchChannel = async () => { 
            try {
               
                setLoading(true);
                const result = await VIEWCREATECHANNELPARTNERAPI();
                if (result?.res?.status === 200) {
                    const data = result?.res?.data?.map((res) => ({
                        id: res?.id,
                        label: res?.channel_partner_name,
                        value: res?.id,
                    }));
    
                    setOptions(prev => ({ ...prev, partners: data }));
                }
                setLoading(false);
            }
            catch (err) {
                setLoading(false);
                toast.error(err)
    
            } 
        };
        
        const fecthData = async () => {
            try {
                setLoading(true);
                const result = await PLANDELETEEDITAPI(data);
                if (result?.res?.status === 200) { 
                    setSelectChannelPartner(result?.res?.data?.plan_detail?.channelPartner);
                    setSelectBranch(result?.res?.data?.plan_detail?.branchLocation);
                    setallData(result?.res?.data)
                }
                setLoading(false);
            }
            catch (err) {
                setLoading(false);
            }
        };

        const fetchBranch = async () => { 
           
            try {
                    setLoading(true);
                    const result = await BRANCLOCATIONLISTAPI({ channelId: selectChannelPartner });
                    if (result?.status === 200) {
                        const data = result?.data?.map((res) => ({
                            id: res?.id,
                            label: res?.BranchLocationName,
                            value: res?.id
                        }));
    
                        setOptions((prev) => ({ ...prev, branch: data }));                      
                    } 
                    setLoading(false);
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                }
        
        };

        useEffect(() => {
            fetchChannel();
            fecthData(); 
        }, []);

           useEffect(() => {
             fetchBranch(); },
            [selectChannelPartner]);

    const subscriptionSchema = yup.object().shape({
        total_days: yup.number()
            .required('Number of days is required')
            .min(1, 'Number of days must be at least 1')
            .max(365, 'Number of days cannot exceed 365'),
        amount: yup.number().required('Amount is required').min(1, 'amount must be at least 1')
    });
    const validationSchema = yup.object().shape({
        formData: yup.object().shape({
            plan_name: yup.string()
                .required('Plan name is required')
                .min(3, 'Plan name must be at least 3 characters'),
            feature: yup.string().required('Description  is required'),
            securityDeposit: yup.number().required('Security deposit is required').positive('Security deposit must be a positive number').integer('Security deposit must be an integer'),
            subscription: yup.array().of(subscriptionSchema),
            range: yup.number()
                .typeError('Range must be a number')
                .required('Range is required')
                .min(1, 'Range must be at least 1'),
  

                tenure: yup.number()
                .typeError('Tenure must be a number')
                .required('Tenure is required')
                .min(1, 'Tenure must be at least 1'),
            max_speed: yup.number()
                .typeError('Max Speed must be a number')
                .required('Max Speed is required')
                .min(1, 'Max Speed must be at least 1'),
       
                tenure: yup.number()
                .typeError('Tenure must be a number')
                .required('Tenure is required')
                .min(1, 'Tenure must be at least 1'),
            is_active: yup.boolean().required(' Status is required'),
        }),
    });
    const handle = async (values) => {
        setLoading(true);
        const { subscription, label, ...formDataWithoutSubscription } = values.formData;
        const _data =
        {
            plan_detail: formDataWithoutSubscription, subscription: values.formData.subscription
        }
       
      
        try {
            const result = await PLANUPDATEAPI(data, _data);
            console.log(result);
            if (result?.res?.status === 201) {
                toast.success(result?.res?.message);
                closeModal()
                reload && reload() 
            }
            else {
                toast.error(result.message);
            }
            setLoading(false);
        }
        
        catch (err) {
            toast.error(err.message);

        }
    }

    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    initialValues={{
                        formData: {
                            planType: "Subscription",
                            channelPartner: allData?.plan_detail?.channelPartner,
                            branchLocation: allData?.plan_detail?.branchLocation,
                            plan_name: allData?.plan_detail?.plan_name ? allData?.plan_detail?.plan_name : '',
                            feature: allData?.plan_detail?.feature ? allData?.plan_detail?.feature : "",
                            securityDeposit: allData?.plan_detail?.securityDeposit ? allData?.plan_detail?.securityDeposit : "",
                            range: allData?.plan_detail?.range ? allData?.plan_detail?.range : "",
                            max_speed: allData?.plan_detail?.max_speed ? allData?.plan_detail?.max_speed : " ",
                            is_active: allData?.plan_detail?.is_active,
                            subscription: allData?.subscription ? allData?.subscription : "",
                            tenure: allData?.plan_detail?.tenure ? allData?.plan_detail?.tenure : "",

                        },
                    }}

                    onSubmit={handle}

                >
                    {({ values, formik }) => (
                        // Wrap your content in the Form component
                        <Form className='form_default text_left'>
                            <div className='mb-3'>
                                <FormControl control="FormHead" title="Update Vehicle Category" />
                            </div>
                            <div className='mb-3'>
                                <div className='row g-3'>
                                      {/* Channel Partner Select */}
                                      <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.channelPartner"}
                                            label={" Channel Partner"}
                                            options={options.partners}
                                            setfield={setSelectChannelPartner}
                                            prevalue={values.formData.channelPartner}
                                            required={true}

                                        />
                                    </div>

                                {/* Select Branch Location  */}

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.branchLocation"}
                                            label={"Branch Location"}
                                            options={options.branch}
                                            setfield={setSelectBranch}
                                            prevalue={selectBranch}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.is_active"}
                                            label={"Vehicle Category Status"}
                                            options={options.status}
                                            prevalue={values.formData.is_active}
                                            required={true}




                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.plan_name"}
                                            type={"text"}
                                            label={"Vehicle Category Name"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.range"}
                                            type={"number"}
                                            label={"Range (Km)"}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.max_speed"}
                                            type={"number"}
                                            label={"Max. Speed (kmph)"}
                                            required={true}

                                        />
                                    </div>
                                    {
                                        values?.formData?.feature && <div className='col-lg-12'>
                                            <FormControl
                                                control="Editor"
                                                name={"formData.feature"}
                                                type={"text"}
                                                label={"Feature (Tag field)"}
                                                value={values?.formData?.feature}
                                                required={true}
                                            />
                                        </div>
                                    }

                                   

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.tenure"}
                                            type={"text"}
                                            label={"Tenure"}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.securityDeposit"}
                                            type={"number"}
                                            label={"Security Deposit (In Rupees)"}
                                            required={true}

                                        />
                                    </div>
                                   
                                    {
                                        values.formData?.subscription && <div className='col-lg-12'>
                                            <FieldArray name="formData.subscription">
                                                {({ push, remove }) => (
                                                    <div>
                                                        <div className='d-flex justify-content-end align-items-center'>
                                                            <div
                                                                className="icon_dash"
                                                                onClick={() => {
                                                                    push({ total_days: '', amount: '' }); // push an empty object when adding new subscription
                                                                }}
                                                            >
                                                                <img src={ADD} alt="Add" />
                                                            </div>
                                                        </div>
                                                        {values?.formData?.subscription?.map((subscription, index) => (
                                                            <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                                                                <div className='col-lg-5'>
                                                                    <FormControl
                                                                        control="InputLabel"
                                                                        label="No of Days "
                                                                        type="number"
                                                                        name={`formData.subscription.${index}.total_days`}
                                                                        required={true}

                                                                    />
                                                                </div>
                                                                <div className='col-lg-5'>
                                                                    <FormControl
                                                                        control="InputLabel"
                                                                        label="Amount (In Rupees) "
                                                                        type="number"
                                                                        name={`formData.subscription.${index}.amount`}
                                                                        required={true}

                                                                    />
                                                                </div>

                                                                {
                                                                    index > 0 && <div
                                                                        className="icon_dash cursor-pointer"
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        <img src={Delete} alt="Delete" />
                                                                    </div>
                                                                }

                                                                {
                                                                    index == 0 &&
                                                                    <div

                                                                    >

                                                                    </div>
                                                                }

                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>
                                    }


                                  
                                    <div className='col-lg-6'>
                                    </div>

                                    <div className='col-lg-6'>
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex justify-content-end'>
                                <div className='col-lg-2 mx-5' onClick={closeModal}>
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>

                                <div className='col-lg-2'>
                                    <Button text="Save" type="submit" />
                                </div>

                            </div>
                            <div>
                                <p>
                                    all asterisk (*) field are required
                                </p>
                            </div>

                        </Form>

                    )}
                </Formik>
            </div>
        </div >
    );
}

export default Subscription;
