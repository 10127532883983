export const LOGIN = "adminManage/login";
export const OTPVERIFY = "adminManage/otp_verification";
export const GETSTATE = "get_state";
export const GETCITY = "get_city";
export const DOCKYARDCREATE = "dockyard/create";
export const VIEWDOCKYARD = "dockyard/viewDockyard";
export const EDITDOCKYARD = "dockyard/";
export const VIEWFLEET = "fleetManagment/viewFleet";
export const CREATECHANNELPARTNER = "channelPartner/create";
export const VIEWCREATECHANNELPARTNER = "channelPartner/";
 export const VIEWCREATECHANNELCHANNEL = "channelPartner"
 export const VIEWFLEETSTATUS="ride/view-customer"
export const FLEETCREATE = "fleetManagment/create";
export const FLEET = "fleetManagment/";
export const GENRATEQR = "fleetManagment/generateQrCode/";
export const ADMINMANAGER = "adminManage/create";
export const VIEWADMINMANAGER = "adminManage/viewAdmin/";
export const EDITADMINMANAGER = "adminManage/";
export const VIEWUSER = "auth/viewCustomer";
export const PLANDETAILSCREATE = "planDetail/create";
export const PLANDETAILSVIEW = "planDetail/viewPlanDetail/";
export const PLANDETAILDELETE = "planDetail/";
export const LinkCREATE = "link/create";
export const LinkDelete = "/link/delete/";
export const GETCUSTOMERALL = "customer/getAll";
export const GETCUSTOMER = "customer/get";
export const GETPROJECTS = "project/getAll";
export const PROJECTUPDATE = "/project/update";
export const GETONEPROJECT = "project/get/";
export const RACICREATE = "masterRaci/create";
export const RACIGET = "masterRaci/getAll";
export const RACIUPDATE = "masterRaci/update/";
export const MILESTONEDELETE = "/task/delete/milestones";
export const UPDATEMILESTONE = "/project/updateStatus";
export const ROLEALL = "/role/getAll";
export const ROLEONE = "role/get/";
export const PROJECTCOMMENT = "/comment/create";
export const NOTIFICATION = "project/getAll/notification";
export const UPLOADCHAT = "/task/chatbotJson";
export const GETCHAT = "project/chatbotJson/";
export const GETCHATCONVERSATION = "project/getProjectConversation/";
export const POSTCHATCONVERSATION = "project/chatConversation";
export const GETREVIEW = "/customer/getReview/employee/";
export const CREATECUSTOMER = "customer/create";
export const UPDATECUSTOMER = "customer/update";
export const PURCHASETASK = "purchase/task/admin";
export const GETSCANS = "/getAllScan";
export const POSTSCANS = "api/scan/start";
export const GETSCAN = "api/scan/getScan/";
export const GENRATESCANREPORT = "api/scan/createReport/";
export const DOWNLOADSCANREPORT = "api/scan/downloadReport/";
export const DOWNLOADSCANREPORTBYID = "api/scan/getReport/";
export const CUSTOMERDELETE = "customer/delete/";
export const CUSTOMERUNDO = "customer/UndoDelete/";
export const SEARCHPROJECTS = "/project/searchProjects";
export const GETALLSCANS = "/api/scan/getAllScan";
export const CLONEDSERVICE = "/task/clone";
export const TEARMSCREATE = "/terms/create";
export const TEARMSGET = "/terms/get";
export const TEARMSGETALL = "/terms/getAll";
export const CREATEBRANCH = "/branchLocation/create";
export const CREATESUBSCRIPTION = "planDetail/createSubscription";
export const REFRESHTOKEN = "/adminManage/refresh_token";
export const CHANGEPASSWORD = "/adminManage/forgot_password";
export const RESENDOTP = "/adminManage/resendOTP";
export const BRANCLOCATION = "/branchLocation";
export const BRANCLOCATIONLIST = "branchLocation/get_branchLocation";
export const PLANTPAYPERUSE = "/planDetail/updatePayUse/";
export const PLANTPAYPERUSEVIEW = "planDetail/payPerUse/";
export const USERCREATED = "auth/addCustomer";
export const USERUPDATES = "auth/updateCustomer/";
export const USERViEW = "auth/editCustomer/";
export const USERViEWV2 = "ride/viewDetailCustomer/";
export const KYCDOC = "kyCustomer/";
export const CHANGEPASSWORDACC = "/adminManage/change_password/";
export const USERPALNS = "/booking/admin_planList";
export const DOCUMENTSVIEW = "/documentView";  
export const Payments = "/payments/UserPaymentByAdmin"; 
export const Transcationlist = "/payments/transactionList"; 
export const Transcationlistdata = "/payments/customertransaction"
export const homedata = "/fleetManagment/home_page" 
export const USERSTATUS = "/ride/view-customer-status"
export const Downloadstatus = "/ride/rider-Summary"
export const DownloadTrip = "/ride/rider-Trip"
export const fleetHistory="fleetHistory/vechileHistory/"
export const Vechiclestatus="ride/vechileReturnStatus"
export const ViewReturnFleet="fleetHistory/viewReturnFleet/"
export const PAYMENY_FLOWUP_API = "/ride/paymentFollowUp/date";
export const PAYMENT_FLOWUP_STATUS_API ="/ride/vechileUpdateStatus"
export const USER_STATUS_INACTIVE_API ="ride/customerStatus/rideData"
