import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Dockyard from "views/admin/dockyard";
import Fleet from "views/admin/Fleet";
import ViewDetails from "views/admin/ViewDetails";
import ViewInformation from "views/admin/ViewInformation";
import Paymentuser from "views/admin/Paymentuser";

import Users from "views/admin/Users";
import ChannelPartner from "views/admin/ChannelMaster";
import Admin from "views/admin/Admin";
import Plans from "views/admin/Plans";
import Logout from "views/auth/Logout";
// import Reports from "views/admin/Reports";
import Transaction from "views/admin/Transaction";
import FleetStatus from "views/admin/FleetStatus";
import Reportstatus from "views/admin/Reportstatus";
import Billing from "views/admin/Billing"
import Fleetstats from "views/admin/Fleetstats";
import Setting from "views/admin/Setting"

const routes = [
  {
    id: 1,
    ref: "dash",
    path: "/",
    name: "Home",
    check: "MainDashboard",
    component: MainDashboard,
    module_id: 9,
    Action: ["view"],
  },
  {
    id: 2,
    ref: "dash",
    path: "/admin",
    name: "Admins & Managers",
    check: "Admins_Managers",
    module_id: 1,
    Action: ["view"],
    component: Admin, // Replace with the actual component for Admins & Managers
  },
  {
    id: 3,
    label: "Channel Partners",
    ref: "dash",
    check: "Channel_Partners",
    module_id: 2,
    Action: ["view"],
    path: "/channel",
    name: "Channel Partners",
    component: ChannelPartner, // Replace with the actual component for Channel Partners
  },
  {
    id: 4,
    label: "Plans",
    ref: "dash",
    path: "/plans",
    name: "Plans",
    check: "Plans",
    module_id: 3,
    Action: ["view"],
    component: Plans, // Replace with the actual component for Plans
  },
  {
    id: 5,
    label: "Fleet Management",
    ref: "dash",
    path: "/fleet",
    name: "Fleet Management",
    component: Fleet,
    check: "Fleet_Management",
    module_id: 4,
    Action: ["view"], // Replace with the actual component for Fleet Management
  },
  {
    id: 6,
    label: "Dockyards",
    ref: "dash",
    path: "/dockyards",
    name: "Dockyards",
    check: "Dockyards",
    module_id: 5,
    Action: ["view"],
    component: Dockyard, // Replace with the actual component for Dockyards
  },
  {
    id: 7,
    label: "Users",
    ref: "dash",
    path: "/users",
    name: "Users",
    check: "Users",
    module_id: 6,
    Action: ["view"],
    component: Users, // Replace with the actual component for Users
  },
 
  {
    id: 8,
    name: "Payment FollowUp",
    ref: "dash",
    path: "/payment",
    check: "payment_followUp",
    module_id: 7,
    Action: ["view"],
    component: Paymentuser,
  },
  
  // {
  //   id: 7,
  //   label: "Users",
  //   ref: "dash",
  //   path: "/user_information:id",
  //   name: "Users",
  //   check: "Users",
  //   module_id: 10,
  //   Action: ["view"],
  //   component: viewInformation,
  // },
  {
    id: 7,
    label: "Users",
    ref: "dash",
    path: "/user_information",
    // name: "Users",
    check: "Users",
    module_id: 10,
    Action: ["view"],
    component: ViewInformation,
  },
  // {
  //   id: 8,
  //   label: "Maintenance",
  //   ref: "dash",
  //   path: "/maintenance",
  //   name: "Maintenance",
  //   check: "Maintenance",
  //   module_id: 7,
  //   Action: ["view"],
  //   component: MainDashboard, // Replace with the actual component for Maintenance
  // },
  // {
  //   id: 9,
  //   label: "Reports",
  //   ref: "dash",
  //   path: "/reports",
  //   name: "Reports",
  //   check: "Reports",
  //   module_id: 8,
  //   Action: ["view"],
  //   component: Reports, // Replace with the actual component for Reports
  // },
  {
    id: 11,
    label: "Transaction",
    ref: "dash",
    path: "/transaction",
    name: "Transaction",
    check: "Transaction",
    module_id: 9,
    Action: ["view"],
    component: Transaction, // Replace with the actual component for Reports
  },
  {
    id: 10,
    label: "",
    ref: "dash",
    path: "/logout",
    name: "",
    component: Logout, // Replace with the actual component for Reports
  },
  {
    id: 10,
    label: "Users",
    ref: "dash",
    path: "/rider_details:id",
    name: "Users",
    check: "Users",
    module_id: 10,
    Action: ["view"],
    component: ViewDetails,
  },
  
  {
    id: 11,
    label: "Fleet Status",
    ref: "dash",
    path: "/fleetstatus",
    name: "Fleet Status",
    check: "FleetStatus",
    module_id: 10,
    Action: ["view"],
    component: FleetStatus,
  },

  {
    id: 11,
    label: "User Status",
    ref: "dash",
    path: "/fleetstats",
    name: "User Status",
    check: "Channel_Partners",
    module_id: 11,
    Action: ["view"],
    component: Fleetstats,
  },

  {
    id: 12,
    label: "Reports",
    ref: "dash",
    path: "/reportstatus",
    name: "Reportstatus",
    check: "FleetReports",
    module_id: 8,
    Action: ["view"],
    component: Reportstatus, 
  },
  // {
  //   id: 13,
  //   label: "Billing",
  //   ref: "dash",
  //   path: "/billing",
  //   name: "Billing",
  //   check: "FleetBilling",
  //   module_id: 8,
  //   Action: ["view"],
  //   component: Billing, 
  // },
  // {
  //   id: 13,
  //   label: "Setting",
  //   ref: "dash",
  //   path: "/setting",
  //   name: "Setting",
  //   check: "FleetSetting",
  //   module_id: 8,
  //   Action: ["view"],
  //   component: Setting, 
  // },
];



export default routes;
