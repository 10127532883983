import axios from "axios";
import {
  LOGIN,
  GETSTATE,
  GETCITY,
  DOCKYARDCREATE,
  VIEWDOCKYARD,
  EDITDOCKYARD,
  VIEWFLEET,
  CREATECHANNELPARTNER,
  VIEWCREATECHANNELPARTNER,
  VIEWCREATECHANNELCHANNEL,
  FLEETCREATE,
  FLEET,
  ADMINMANAGER,
  OTPVERIFY,
  VIEWUSER,
  PLANDETAILSCREATE,
  PLANDETAILSVIEW,
  PLANDETAILDELETE,
  GENRATEQR,
  VIEWADMINMANAGER,
  EDITADMINMANAGER,
  CREATESUBSCRIPTION,
  CREATEBRANCH,
  CHANGEPASSWORD,
  RESENDOTP,
  BRANCLOCATION,
  PLANTPAYPERUSE,
  PLANTPAYPERUSEVIEW,
  BRANCLOCATIONLIST,
  USERCREATED,
  USERViEW,
  USERUPDATES,
  KYCDOC,
  CHANGEPASSWORDACC,
  USERPALNS,
  DOCUMENTSVIEW,
  Payments,
  Transcationlist,
  Transcationlistdata,
  homedata,
  VIEWFLEETSTATUS,
  USERSTATUS,
  Downloadstatus,
  DownloadTrip,
  fleetHistory,
  PAYMENY_FLOWUP_API,
  PAYMENT_FLOWUP_STATUS_API,
  USERViEWV2,
  Vechiclestatus,
  ViewReturnFleet,
  USER_STATUS_INACTIVE_API,
} from "../CustomRoutes";

// export const BASE_URL = "https://api.zeway.techtonic.asia/";
// production =  https://uatfleet.zeway.co.in/
//= https://prdfleet.zeway.co.in (ravi)

export const BASE_URL = "https://prdfleet.zeway.co.in";

// produc
axios.defaults.baseURL = BASE_URL;

export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const OTPVERIFYService = async (data) => {
  try {
    const response = await axios.post(OTPVERIFY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETSTATEAPI = async () => {
  try {
    const response = await axios.get(GETSTATE, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETCITYAPI = async (data) => {
  try {
    const response = await axios.post(GETCITY, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const BRANCLOCATIONLISTAPI = async (data) => {
  try {
    const response = await axios.post(BRANCLOCATIONLIST, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DOCKYARDCREATEAPI = async (data) => {
  try {
    const response = await axios.post(DOCKYARDCREATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const VIEWDOCKYARDAPI = async () => {
  try {
    const response = await axios.get(VIEWDOCKYARD, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const EDITDOCKYARDAPI = async (id) => {
  try {
    const response = await axios.get(`${EDITDOCKYARD}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const UPDATEDOCKYARDAPI = async (id, data) => {
  try {
    const response = await axios.put(`${EDITDOCKYARD}${id}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DELETEDOCKYARDAPI = async (id) => {
  try {
    const response = await axios.delete(`${EDITDOCKYARD}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const VIEWFLEETDAPI = async () => {
  try {
    const response = await axios.get(`${VIEWFLEET}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const CREATECHANNELPARTNERAPI = async (data) => {
  try {
    const response = await axios.post(`${CREATECHANNELPARTNER}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const VIEWCREATECHANNELPARTNERAPI = async () => {
  try {
    const response = await axios.get(`${VIEWCREATECHANNELPARTNER}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const VIEWCREATECHANNELPARTNERAPIChannel = async (id) => {
  try {
    const response = await axios.get(`${VIEWCREATECHANNELCHANNEL}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const VIEWCHANNELFLEETSTATUS = async (id) => {
  try {
    const response = await axios.get(`${VIEWFLEETSTATUS}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};


export const DELETECREATECHANNELPARTNERAPI = async (id) => {
  try {
    const response = await axios.delete(`${VIEWCREATECHANNELPARTNER}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EDITCREATECHANNELPARTNERAPI = async (id) => {
  try {
    const response = await axios.get(`${VIEWCREATECHANNELPARTNER}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const UPDATECREATECHANNELPARTNERAPI = async (id, data) => {
  try {
    const response = await axios.put(`${VIEWCREATECHANNELPARTNER}${id}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const FLEETCREATEAPI = async (data) => {
  try {
    const response = await axios.post(`${FLEETCREATE}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const FLEETDELETEAPI = async (id) => {
  try {
    const response = await axios.delete(`${FLEET}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const FLEETEDITAPI = async (id) => {
  try {
    const response = await axios.get(`${FLEET}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const FLEETUPDATEAPI = async (id, data) => {
  try {
    const response = await axios.put(`${FLEET}${id}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ADMINMANAGERAPI = async (data) => {
  try {
    const response = await axios.post(`${ADMINMANAGER}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const VIEWUSEREAPI = async () => {
  try {
    const response = await axios.get(`${VIEWUSER}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PLANDETAILSCREATEAPI = async (data) => {
  try {
    const response = await axios.post(`${PLANDETAILSCREATE}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PLANDETAILSVIEWAPI = async () => {
  try {
    const response = await axios.get(`${PLANDETAILSVIEW}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PLANDELETEAPI = async (id) => {
  try {
    const response = await axios.delete(`${PLANDETAILDELETE}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const PLANDELETEEDITAPI = async (id) => {
  try {
    const response = await axios.get(`${PLANDETAILDELETE}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const PLANUPDATEAPI = async (id, data) => {
  try {
    const response = await axios.put(`${PLANDETAILDELETE}${id}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GENRATEQRAPI = async (id, id2, id3,fleet_id) => {
  try {
    const response = await axios.get(
      `${GENRATEQR}${id}?dockyard=${id2}&fleet_id=${fleet_id}&planType=${id3}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const VIEWADMINMANAGERPI = async () => {
  try {
    const response = await axios.get(`${VIEWADMINMANAGER}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EDITADMINMANAGERPI = async (id) => {
  try {
    const response = await axios.get(`${EDITADMINMANAGER}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const UPDATEADMINMANAGERPI = async (id, data) => {
  try {
    const response = await axios.put(`${EDITADMINMANAGER}${id}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CREATESUBSCRIPTIONAPI = async (data) => {
  try {
    const response = await axios.post(`${CREATESUBSCRIPTION}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CREATEBRANCHAPI = async (data) => {
  try {
    const response = await axios.post(`${CREATEBRANCH}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const ThirdPartyAPI = async () => {
  try {
    const response = await axios.post(
      "http://139.84.165.121:8000/api/location/get-by-imei/",
      { terminalID: ["867111062324550"] },
      {
        headers: {
          Authorization: "token 3ad51eb50145b6ea99c7529f002da8c61b36437b",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};
export const CHANGEPASSWORDAPI = async (data) => {
  try {
    const response = await axios.post(`${CHANGEPASSWORD}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const RESENDOTPAPI = async (data) => {
  try {
    const response = await axios.post(`${RESENDOTP}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const BRANCLOCATIONAPI = async () => {
  try {
    const response = await axios.get(`${BRANCLOCATION}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const BRANCLOCATIONEDITAPI = async (data) => {
  try {
    const response = await axios.get(
      `${BRANCLOCATION}/${data}`,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PLANTPAYPERUSEAPI = async (data, data_2) => {
  try {
    const response = await axios.put(
      `${PLANTPAYPERUSE}${data}`,
      data_2,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PLANTPAYPERUSEVIEWAPI = async (data) => {
  try {
    const response = await axios.get(
      `${PLANTPAYPERUSEVIEW}${data}`,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const BRANCLOCATIONUPDATEAPI = async (data, data2) => {
  try {
    const response = await axios.put(
      `${BRANCLOCATION}/${data}`,
      data2,

      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const USERCREATEDAPI = async (data) => {
  try {
    const response = await axios.post(`${USERCREATED}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const USERViEWAPI = async (data) => {
  try {
    const response = await axios.get(`${USERViEW}${data}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};


export const USERViEWAPIV2 = async (data) => {
  try {
    const response = await axios.get(`${USERViEWV2}${data}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};


export const USERUPDATESAPI = async (data, data2) => {
  try {
    const response = await axios.put(`${USERUPDATES}${data}`, data2, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const KYCDOCAPI = async (data) => {
  try {
    const response = await axios.get(`${KYCDOC}${data}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const CHANGEPASSWORDACCAPI = async (data) => {
  try {
    const response = await axios.post(`${CHANGEPASSWORDACC}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const USERPALNSAPI = async (data) => {
  try {
    const response = await axios.post(`${USERPALNS}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DOCUMENTSVIEWAPI = async (data) => {
  try {
    const response = await axios.post(`${DOCUMENTSVIEW}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const VechiceStatusData = async (data) => {
  try {
    const response = await axios.post(`${Vechiclestatus}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const PaymentsAPI = async (data) => {
  try {
    const response = await axios.post(`${Payments}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const TranscationlistAPI = async () => {
  try {
    const response = await axios.get(`${Transcationlist}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// export const TranscationlistAPIdata = async () => {
//   try {
//     const response = await axios.get(`${Transcationlistdata}`, {
//       headers: { "Content-Type": "application/json" },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };


export const TranscationlistAPIdata = async (data) => {
  try {
    const response = await axios.post(`${Transcationlistdata}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};


export const FleetHistoryData = async (data) => {
  try {
    const response = await axios.get(`${fleetHistory}${data}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETHOMEAPI = async () => {
  try {
    const response = await axios.get(homedata, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};



export const ViewReturnFleetData = async (data) => {
  try {
    const response = await axios.get(`${ViewReturnFleet}${data}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};


export const USERSTATUSDATA = async (id) => {
  try {
    const response = await axios.get(`${USERSTATUS}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};






export const Downloadstatusdata = async (id) => {
  try {
    const response = await axios.get(`${Downloadstatus}${id}`, {
      headers: { "Content-Type": "application/json" },
      responseType: "blob",
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};



export const DownloadstatusTrip = async (id) => {
  try {
    const response = await axios.get(`${DownloadTrip}${id}`, {
      headers: { "Content-Type": "application/json" },
      responseType: "blob",
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};



export const PAYMENTFLOWYOAPISERVICES = async () => {
  try {
    const response = await axios.get(`${PAYMENY_FLOWUP_API}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const PAYMENTSTATUSAPISERVICES = async () => {
  try {
    const response = await axios.post(`${PAYMENT_FLOWUP_STATUS_API}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};


export const USER_INACTIVE_SERVICES = async (id) => {
  try {
    const response = await axios.get(`${USER_STATUS_INACTIVE_API}/${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};