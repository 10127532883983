import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import ADD from "assets/img/dashboards/add_circle.svg";
import Delete from "assets/img/dashboards/delete.svg"
import { BRANCLOCATIONLISTAPI, VIEWCREATECHANNELPARTNERAPI, CREATESUBSCRIPTIONAPI } from 'utils/Services';
import * as yup from 'yup';
import { toast } from 'react-toastify';




function Subscription({ closeModal, reload }) {
    const [options, setOptions] = useState({
        partners: [], branch: [], status: [{
            id: true,
            label: 'Active',
            value: true
        }, {
            id: false,
            label: 'InActive',
            value: false
        }]
    });

    const [loading, setLoading] = useState(false)
    const [selectChannelPartner, setSelectChannelPartner] = useState()

        const fecthData = async () => {
            try {
                setLoading(true)
                const result = await VIEWCREATECHANNELPARTNERAPI();
                if (result?.res?.status === 200) {
                    const data = result?.res?.data?.map((res) => ({
                        id: res?.id,
                        label: res?.channel_partner_name, // Assuming channel_partner_name is a property of the 'res' object
                        value: res?.channel_partner_name,
                    }));
    
                    setLoading(false)
                    setOptions((res) =>
                    ({
                        ...res,
                        partners: data
                    }))
                }
            }
            catch (err) {
                setLoading(false);
                toast.error(err)
    
            }
        }

        const fetchBranchData = async () => {
    
            if (selectChannelPartner !== '' && selectChannelPartner !== null && selectChannelPartner !== undefined) {
                const data = {
                    "channelId": selectChannelPartner
                };
    
                try {
                    setLoading(true);
                    const result = await BRANCLOCATIONLISTAPI(data);
    
                    if (result?.status === 200) {
                        const data = result?.data?.map((res) => ({
                            id: res?.id,
                            label: res?.BranchLocationName,
                            value: res?.id
                        }));
    
                        setOptions((res) => ({
                            ...res,
                            branch: data
                        }));
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                }
            }
        }
        
        useEffect(() => {
               fecthData()
           }, []);
           useEffect(() => { fetchBranchData(); }, [selectChannelPartner]);

    const subscriptionSchema = yup.object().shape({
        total_days: yup.number()
            .required('Number of days is required')
            .min(1, 'Number of days must be at least 1')
            .max(365, 'Number of days cannot exceed 365'),
        amount: yup.number().required('Amount is required').min(1, 'amount must be at least 1')
    });
    const validationSchema = yup.object().shape({
        formData: yup.object().shape({
            plan_name: yup.string().required('Plan name is required'),
            feature: yup.string().required('Feature  is required'),
            securityDeposit: yup.number().required('Security deposit is required').positive('Security deposit must be a positive number').integer('Security deposit must be an integer'),
            subscription: yup.array().of(subscriptionSchema),
            range: yup.number()
                .typeError('Range must be a number')
                .required('Range is required')
                .min(1, 'Range must be at least 1'),
            tenure: yup.number()
                .typeError('Tenure must be a number')
                .required('Tenure is required')
                .min(1, 'Tenure must be at least 1'),
            max_speed: yup.number()
                .typeError('Max Speed must be a number')
                .required('Max Speed is required')
                .min(1, 'Max Speed must be at least 1'),
            is_active: yup.boolean().required(' Status is required'),
        }),
    });
    const handle = async (values) => {
        const { subscription, label, ...formDataWithoutSubscription } = values.formData;
        const data =
        {
            planDetail: formDataWithoutSubscription, subscription: values.formData.subscription
        }
        try {
            const result = await CREATESUBSCRIPTIONAPI(data);
            if (result?.status === 201) {
                toast.success(result.message);
                closeModal()
                { reload && reload() }
            }
            else {
                toast.error(result.res.message);
                closeModal()
            }
        }
        catch (err) {
            toast.error(err.message);

        }
    }

    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik
                    initialValues={{
                        formData: {
                            "planType": "Subscription",
                            "channelPartner": "",
                            "branchLocation": "",
                            "plan_name": "",
                            "feature": "",
                            "securityDeposit": "",
                            "range": "",
                            "max_speed": "",
                            "is_active": '',
                            'tenure': '',
                            'subscription': [{ total_days: '', amount: '' }]

                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handle}

                >
                    {({ values, handleSubmit, setFieldValue }) => (
                        // Wrap your content in the Form component
                        <Form className='form_default text_left'>
                            <div className='mb-3'>
                                <FormControl control="FormHead" title="Add New Vehicle Category" />
                            </div>
                            <div className='mb-3'>
                                <div className='row g-3'>

                                    {/* Channel Partner Select */}
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.channelPartner"}
                                            label={" Channel Partner"}
                                            options={options.partners}
                                            setfield={setSelectChannelPartner}
                                            required={true}

                                        />
                                    </div>

                                {/* Select Branch Location  */}

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.branchLocation"}
                                            label={"Branch Location"}
                                            options={options.branch}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.is_active"}
                                            label={"Vehicle Category Status"}
                                            options={options.status}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.plan_name"}
                                            type={"text"}
                                            label={"Vehicle Category Name"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.range"}
                                            type={"text"}
                                            label={"Range (Km)"}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.max_speed"}
                                            type={"text"}
                                            label={"Max. Speed (kmph)"}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-12'>
                                        <FormControl
                                            control="Editor"
                                            name={"formData.feature"}
                                            type={"text"}
                                            label={"Feature (Tag field)"}
                                            required={true}


                                        />
                                    </div>

                               

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.tenure"} 
                                            type={"text"}
                                            label={"Tenure"}
                                            required={true}
                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.securityDeposit"}
                                            type={"number"}
                                            label={"Security Deposit (In Rupees)"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-12'>
                                        <FieldArray name="formData.subscription">
                                            {({ push, remove }) => (
                                                <div>
                                                    <div className='d-flex justify-content-end align-items-center'>
                                                        <div
                                                            className="icon_dash"
                                                            onClick={() => {
                                                                push({ total_days: '', amount: '' }); // push an empty object when adding new subscription
                                                            }}
                                                        >
                                                            <img src={ADD} alt="Add" />
                                                        </div>
                                                    </div>
                                                    {values?.formData?.subscription?.map((subscription, index) => (
                                                        <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                                                            <div className='col-lg-5'>
                                                                <FormControl
                                                                    control="InputLabel"
                                                                    label="No of Days "
                                                                    type="number"
                                                                    required={true}

                                                                    name={`formData.subscription.${index}.total_days`}
                                                                />
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <FormControl
                                                                    control="InputLabel"
                                                                    label="Amount (In Rupees) "
                                                                    type="number"
                                                                    required={true}

                                                                    name={`formData.subscription.${index}.amount`}
                                                                />
                                                            </div>

                                                            {
                                                                index > 0 && <div
                                                                    className="icon_dash cursor-pointer"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    <img src={Delete} alt="Delete" />
                                                                </div>
                                                            }

                                                            {
                                                                index == 0 &&
                                                                <div

                                                                >

                                                                </div>
                                                            }

                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                    </div>

                                    <div className='col-lg-6'>
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex justify-content-end'>
                                <div className='col-lg-2 mx-5' onClick={closeModal}>
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>

                                <div className='col-lg-2'>
                                    <Button text="Save" type="submit" />
                                </div>

                            </div>

                            <div>
                                <p>
                                    all asterisk (*) field are required
                                </p>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default Subscription;
